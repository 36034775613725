import React from "react"

import store from "../assets/image/10.png"
import kobiTxt from "../assets/image/11.png"
import kobiBg from "../assets/image/12.png"
import moneyBag from "../assets/image/9.png"

const Kobi = () => {
  const card = (icon, title, text) => {
    return (
      <div class="shadow p-3 bg-white rounded d-flex">
        <div>
          <img style={{ width: "50px" }} src={icon} />
        </div>
        <div className="container">
          <h5 className="mb-3">{title}</h5>
          <p style={{ fontSize: "13px" }}>{text}</p>
        </div>
      </div>
    )
  }
  return (
    <section className="how-its-work-area index-why-choose-us mt-2">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <h4 className="mb-3">İşletmende rekabet gücünü yakala!</h4>
              <p>
                Üye iş yeri olarak onbinlerce kullanıcının harcamasını
                yapabileceği noktalar arasına katılarak işlem hacmini artır ve
                benzersiz ayrıcalıklardan faydalan. Üstelik ÜCRETSİZ!
              </p>

              {card(
                moneyBag,
                "Yeni gelir kaynakları üret",
                "Kullanıcılar tasarruf etmek için tüketimlerini anlaşmalı noktalara yönlendiriyor. Müşterilerine vereceğin makul bir iskonto oranı ile hemen anlaşmalı noktalar arasında yerini al ve yeni müşteriler kazanmaya başla."
              )}
              <div style={{ height: "20px" }} />
              {card(
                store,
                "Rekabet Gücünü Yakala",
                "Alışverişlio, iş ortakları ( Kobiler) için finansal argümanlar noktasında benzersiz ayrıcalıklar sunar. Onbinlerce işletme ile aynı toplulukta olmak cironuzu artırırken aynı zamandan maliyetlerinizi düşürür."
              )}

              <div className="container"></div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <div style={{ position: "relative" }}>
                <img src={kobiBg} alt="choose" />
                <img
                  src={kobiTxt}
                  alt="choose"
                  style={{
                    width: "100px",
                    position: "absolute",
                    top: -20,
                    left: 20,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Kobi
