import React from "react"
import img from "../assets/image/6.png"
import arrow from "../assets/image/7.png"

const Bazaar = () => {
  const gridItem = title => {
    return (
      <div className="d-flex align-items-center">
        <img className="mr-3" width={30} src={arrow} />
        <div style={{ width: "5px" }} />
        <p>{title}</p>
      </div>
    )
  }
  return (
    <section className="how-its-work-area index-why-choose-us mt-2">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img src={img} alt="choose" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              {/* <span className="sub-title">
              <img src={starIcon} alt="choose" />
              Why Choose Us
            </span> */}
              <h4 className="mb-3">Çarşıda kolayca fazlalıklarını sat.</h4>
              <p>
                Hem sıfır hem ikinci el ürünlerini düşük komisyon oranları ile
                nakite çevir. Çarşıdan yapacağın her alışverişten de yine anında
                nakit iade kazan.
              </p>

              <div className="container">
                <div className="row">
                  <div className="col">{gridItem("Düşük komisyon")}</div>
                  <div className="col">{gridItem("Kolay Kullanım")}</div>
                </div>
                <div className="row mt-2">
                  <div className="col">{gridItem("Zahmetsiz Kargo")}</div>
                  <div className="col">{gridItem("Lokasyon Bazlı Satış")}</div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    {gridItem("Bireysel Satıcı Olabilme")}
                  </div>
                  <div className="col">
                    {gridItem("Paran aynı gün hesabında")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Bazaar
