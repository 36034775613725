import React, { useEffect, useRef } from "react"
import Layout from "../components/App/Layout"

import Navbar from "../components/App/Navbar"
import OurSolutions from "../components/DataAnalyticsMLConsulting/OurSolutions"
import MainBanner from "../components/DataScienceAndMLCompany/MainBanner"
import WhatAlisverislio from "../components/SEOAgency/WhyChooseUs.js"

import Footer from "../components/App/Footer"

import fiveSec from "../assets/image/5.png"
import tema from "../assets/images/tema.png"
import Bazaar from "../components/bazaar.js"
import Kobi from "../components/kobi.js"
import BottomButtonsGrid from "./bottom-buttons-grid.js"
const Index = () => {
  const bazaarRef = useRef(null)
  const kobiRef = useRef(null)

  const addLiveChat = () => {
    const inner = `(function(w,d,u){
      var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
      var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
})(window,document,'https://cdn.bitrix24.com.tr/b27663209/...f.js');`
    const script = document.createElement("script")
    script.innerHTML = inner
    document.body.appendChild(script)
  }

  const focusSection = () => {
    const hash = window.location.hash
    if (hash) {
      const targetElement = document.querySelector(hash)
      if (targetElement) {
        // Hedef element bulunduğunda odaklan
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          top: -70,
        })
      }
    }
  }

  useEffect(() => {
    addLiveChat()
    focusSection()
  }, [])

  const onTapNavItem = index => {
    if (index === 0) {
      kobiRef.current?.scrollIntoView({ behavior: "smooth" })
    } else if (index === 1) {
      bazaarRef.current?.scrollIntoView({
        behavior: "smooth",
        top: -70,
      })
    }
  }

  return (
    <Layout>
      <Navbar onTap={onTapNavItem} />

      <MainBanner />

      {/* <Partner /> */}
      <WhatAlisverislio />
      <div className="container mt-4 pt-4">
        <img src={tema} alt="tema" />
      </div>
      <OurSolutions />

      <div className="container">
        <img src={fiveSec} alt="choose" />
      </div>

      <div id="carsi" ref={bazaarRef}>
        <Bazaar />
      </div>

      <div id="kobi" ref={kobiRef}>
        <Kobi />
      </div>

      {/* <div className="container mt-5">
        <img src={shoppingAndKindness} alt="choose" />
      </div> */}

      {/* <Crew /> */}

      <BottomButtonsGrid />

      {/* <Buttons /> */}
      {/*  <img className="mt-3 mb-1" src={helpBanner} /> */}
      {/* <BusinessPartnerForm formRef={formRef} /> */}
      {/* <BottomButtons /> */}
      <Footer />
    </Layout>
  )
}

export default Index
