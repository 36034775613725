import { Link } from "gatsby"
import React from "react"

const OurSolutions = () => {
  return (
    <section className="solutions-area mt-5">
      <div className="container">
        {/* <div className="text-justify text-center mb-4">
          <span className="sub-title">
            <img src={StarIcon} alt="image" />
            Our Solutions
          </span>
          <h4>
            Temel tüketim harcamalarını gelire dönüştür.<br></br> Ortalama
            Yıllık 4.800₺* nakit iade kazan.
          </h4>

          <h3 className="py-3"> Neden daha fazla ödeyesin ?</h3>
          <p>
            * Aşağıdaki örnekteki gibi aylık 8.000₺ tüketimde yıllık 4.800₺
            nakit iade kazanabilirsin. Tüketim miktarının daha fazla olması
            durumunda kazandığın miktar orantılı olarak artacaktır.
          </p>
        </div> */}
        {/* <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <img src={gain1} />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <img src={gain2} />
          </div>
        </div> */}

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-rocket"></i>
              </div>
              <h4>
                <Link to="/service-details">Kazananlardan ol</Link>
              </h4>
              <p>
                Her gün on binlerce kullanıcı, yüz binlerce lira nakit iade
                alıyor. En bilindik markalardan güvenle ve keyifle
                alışverişlerini yaparken, her alışverişinden anında hesabına
                yatan bakiye ile sende kazananlardan ol.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>

              <h4>
                <Link to="/service-details">Bu uygulama ÜCRETSİZ</Link>
              </h4>

              <p>
                Tüm Türkiye’de her kategoride on binlerce noktada bir gün değil
                her gün kazan. Paracık yada puan değil, doğrudan nakit iade
                kazan. Firmaların kendi indirimleri ve kampanyalarına ek olarak
                bu iadeleri al.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-money"></i>
              </div>

              <h4>
                <Link to="/service-details">Yeni Nesil Cashback</Link>
              </h4>

              <p>
                Alışverişlerinde kazandığın iadeleri, hesabına çekebilir,
                sonraki alışverişlerinde kullanabilir yada altın birikim
                hesabına aktarabilirsin. Ya da ihtiyaç sahibi bir tanıdığını
                hesabına tanımlayarak ona yatmasını sağlayabilirsin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurSolutions
