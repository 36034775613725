import React from "react"
// import gain from "../assets/image/15.png"
import lioCard from "../assets/image/lio_soon.jpeg"
// import kobiLio from "../assets/image/17.png"
import premium from "../assets/image/premium_soon.jpeg"

const BottomButtonsGrid = () => {
  return (
    <section className="container  py-4">
      <div className="row mb-5 align-items-center ">
        <div className="col d-flex justify-content-center">
          <img src={premium} />
        </div>

        <div className="col d-flex justify-content-center">
          <img src={lioCard} />
        </div>
      </div>
      {/* <div className="row mt-4 align-items-center">
        <div className="col d-flex justify-content-center">
          <img src={gain} />
        </div>
        <div className="col d-flex justify-content-center">
          <img src={kobiLio} />
        </div>
      </div> */}
    </section>
  )
}

export default BottomButtonsGrid
